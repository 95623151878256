<script>
import { defineComponent } from "vue";
import GuestLectureItem from "@/views/core/Landing/Courses/GuestLectures/GuestLectureItem.vue";
import TheButton from "@/components/Button/Button.vue";

export default defineComponent({
  name: "GuestLectures",
  components: {TheButton, GuestLectureItem },
  data() {
    return {
      guests: [
        {
          name: "Асхат Молкенов",
          company: "Oraclus",
          position: "Руководитель команды",
          img: "askhat.jpeg",
          logo: "oraclus-white.png",
        },
        {
          name: "Абдиева Камила",
          company: "UIUC",
          position: "Research Assistant",
          img: "kamila.png",
          logo: "uiuc.png",
        },
        {
          name: "Себепова Алина",
          company: "ONE technologies",
          position: "UX/UI дизайнер",
          img: "alina.png",
          logo: "one_tech.png",
        },
        {
          name: "Нуржан Нурсеитов",
          company: 'АО НК "Қазақстан Темір Жолы"',
          position: "IT аудитор",
          img: "nurzhan.jpeg",
          logo: "ktzh.png",
        },
      ],
    };
  },
});
</script>

<template>
  <div class="guest-lectures__body">
    <h2>Гостевые лекторы</h2>
    <div class="guest-lectures__grid">
      <GuestLectureItem
        v-for="(guest, index) in guests"
        :key="index"
        :guest="guest"
      />
    </div>
    <div>
      <router-link :to="{name: 'All Guest Lectures'}">
        <div style="display: flex; justify-content: end;">
          <TheButton class="guest-lectures__button">
            Все лекторы
            <svg width="20" height="20"  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9167 6.41753C10.8395 6.4938 10.7782 6.58463 10.7363 6.68476C10.6945 6.7849 10.673 6.89234 10.673 7.00086C10.673 7.10938 10.6945 7.21682 10.7363 7.31696C10.7782 7.41709 10.8395 7.50793 10.9167 7.58419L12.5 9.16753H4.83333C4.375 9.16753 4 9.54253 4 10.0009C4 10.4592 4.375 10.8342 4.83333 10.8342H12.5L10.9167 12.4175C10.8395 12.4938 10.7782 12.5846 10.7363 12.6848C10.6945 12.7849 10.673 12.8923 10.673 13.0009C10.673 13.1094 10.6945 13.2168 10.7363 13.317C10.7782 13.4171 10.8395 13.5079 10.9167 13.5842C11.2417 13.9092 11.7583 13.9092 12.0833 13.5842L15.075 10.5925C15.1523 10.5154 15.2135 10.4239 15.2554 10.323C15.2972 10.2222 15.3187 10.1142 15.3187 10.005C15.3187 9.89589 15.2972 9.78782 15.2554 9.68701C15.2135 9.5862 15.1523 9.49462 15.075 9.41753L12.0833 6.41753C12.0071 6.34033 11.9162 6.27904 11.8161 6.2372C11.716 6.19537 11.6085 6.17383 11.5 6.17383C11.3915 6.17383 11.284 6.19537 11.1839 6.2372C11.0838 6.27904 10.9929 6.34033 10.9167 6.41753Z" fill="black"/>
            </svg>
          </TheButton>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.guest-lectures__body {
  padding: 64px 100px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background: #06172b;
}
h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
}
.guest-lectures__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

@media (max-width: 650px) {
  .guest-lectures__body {
    padding: 40px 16px;
    gap: 24px;
  }
  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: left;
  }
  .guest-lectures__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

.guest-lectures__button{
  border-radius: 32px;
  background: #ffffff;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  gap: 12px;
}
</style>
