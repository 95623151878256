<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    date: String,
    speaker: String,
    description: String,
    badge: String,
    image: String,
  },
});
</script>

<template>
  <div class="list-item">
    <p class="list-item-heading">{{ date }}</p>
    <div class="list-item-container">
      <div class="list-item-content">
        <img :src="image" alt="avatar" class="list-item-picture" />
        <div class="list-item-text">
          <h2>{{ speaker }}</h2>
          <p>{{ description }}</p>
        </div>
      </div>
      <p class="list-item-badge">{{ badge }}</p>
    </div>
  </div>
</template>

<style scoped>


.list-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 64px;
}

.list-item-heading {
  font-size: 24px;
  font-weight: 700;
  color: #a599f5;
  white-space: nowrap;
  min-width: 120px;
}

.list-item-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
}



.list-item-content {
  display: flex;
  gap: 24px;

  align-items: center;
}


.list-item-picture {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}


.list-item-badge {
  font-size: 14px;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 24px;
  color: #a599f5;
  background: rgba(165, 153, 245, 0.2);
  width: fit-content;
}

.list-item-text h2 {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}

.list-item-text p {
  font-size: 18px;
  color: #9ca3af;
}


@media (max-width: 1024px) {
  .list-item {
    flex-direction: column;
    gap: 32px;
  }

  .list-item-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .list-item-picture {
    width: 70px;
    height: 70px;
  }
  .list-item-text{
    max-width: 800px;
  }

  .list-item-text h2 {
    font-size: 20px;
  }

  .list-item-text p {
    font-size: 16px;
  }

  .list-item-heading {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .list-item {
    gap: 24px;
  }

  hr{
    width: 85%;
  }

  .list-item-container {
    gap: 12px;
  }

  .list-item-picture {
    width: 60px;
    height: 60px;
  }
  .list-item-text{
    max-width: 600px;
  }

  .list-item-text h2 {
    font-size: 18px;
  }

  .list-item-text p {
    font-size: 14px;
  }

  .list-item-heading {
    font-size: 18px;
  }

  .list-item-badge {
    font-size: 12px;
    padding: 6px 10px;
  }
}

@media (max-width: 400px) {
  .list-item-text{
    max-width: 300px;
  }
}
</style>
