<template>
  <TheCorePageHeader>
    <TheLandingSidebar
      :landing-sidebar="landingSidebar"
      :close-sidebar="closeSidebar"
    />
    <ShoppingCartModal
      @close="closeShoppingCartModal"
      :modal-open="modalOpen"
    />

    <div class="flex-1 min-w-0">
      <div class="flex place-items-center gap-10">
        <TheLogo class="mr-2" route-name="TheLandingPage" />
        <router-link :to="{ name: 'AllCourses' }" class="hidden md:block">
          <TheButton
            bg="link"
            :class="
              currentRoute === 'AllCourses'
                ? 'current-route'
                : 'not-current-route'
            "
            style="font-weight: 400"
          >
            {{ $t("courses") }}
          </TheButton>
        </router-link>
        <router-link :to="{ name: 'Services' }" class="hidden md:block">
          <TheButton
            bg="link"
            :class="
              currentRoute === 'Services'
                ? 'current-route'
                : 'not-current-route'
            "
            style="font-weight: 400"
          >
            {{ $t("services") }}
          </TheButton>
        </router-link>
        <router-link :to="{ name: 'About Us' }" class="hidden md:block">
          <TheButton
            bg="link"
            :class="
              currentRoute === 'About Us'
                ? 'current-route'
                : 'not-current-route'
            "
            style="font-weight: 400"
          >
            {{ $t("about_us") }}
          </TheButton>
        </router-link>
        <router-link :to="{ name: 'Tariffs' }" class="hidden md:block">
          <TheButton
            bg="link"
            :class="
              currentRoute === 'Tariffs' ? 'current-route' : 'not-current-route'
            "
            style="font-weight: 400"
          >
            {{ $t("tariffs") }}
          </TheButton>
        </router-link>
        <div class="hidden md:block">
          <TheButton
            bg="link"
            :class="
              currentRoute === 'services' ? 'text-indigo-600 ' : 'text-gray-800'
            "
            class="hidden md:block"
            style="font-weight: 400"
            @click="scrollToFooter"
          >
            {{ $t("contacts_low") }}
          </TheButton>
        </div>

        <!--        <router-link :to="{name:'MyCourses'}" v-if="isAuthenticated" class="hidden md:block pr-3">-->
        <!--          <TheButton-->
        <!--              :bg="isMyCoursesRoute?'link-active':'link'" style="font-weight: 400">-->
        <!--            {{ $t('my_education') }}-->
        <!--          </TheButton>-->
        <!--        </router-link>-->
        <!--        <GlobalSearchInput/>-->
      </div>
    </div>

<!--    <router-link :to="{ name: 'ShoppingCart' }" class="block md:hidden">-->
<!--      <div class="mr-6 cursor-pointer relative">-->
<!--        <div-->
<!--          class="absolute top-0 right-0 cart-number"-->
<!--          v-if="cartItems.length > 0"-->
<!--        >-->
<!--          {{ cartItems.length }}-->
<!--        </div>-->
<!--        <div class="icon-container">-->
<!--          <svg-->
<!--            width="24"-->
<!--            height="24"-->
<!--            viewBox="0 0 24 24"-->
<!--            fill="none"-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--          >-->
<!--            <path-->
<!--              d="M3 3H5L5.4 5M7 13H17L21 5H5.4M7 13L5.4 5M7 13L4.70711 15.2929C4.07714 15.9229 4.52331 17 5.41421 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM9 19C9 20.1046 8.10457 21 7 21C5.89543 21 5 20.1046 5 19C5 17.8954 5.89543 17 7 17C8.10457 17 9 17.8954 9 19Z"-->
<!--              stroke="#111827"-->
<!--              stroke-width="1.7"-->
<!--              stroke-linecap="round"-->
<!--              stroke-linejoin="round"-->
<!--            />-->
<!--          </svg>-->
<!--        </div>-->
<!--      </div>-->
<!--    </router-link>-->
    <div class="flex justify-end mob-header gap-2">
      <button
        v-if="!landingSidebar"
        type="button"
        class="hidden border-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 sidebar"
        @click="openSidebar"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 2C0 1.73478 0.105357 1.48043 0.292893 1.29289C0.48043 1.10536 0.734784 1 1 1H15C15.2652 1 15.5196 1.10536 15.7071 1.29289C15.8946 1.48043 16 1.73478 16 2C16 2.26522 15.8946 2.51957 15.7071 2.70711C15.5196 2.89464 15.2652 3 15 3H1C0.734784 3 0.48043 2.89464 0.292893 2.70711C0.105357 2.51957 0 2.26522 0 2ZM0 8C0 7.73478 0.105357 7.48043 0.292893 7.29289C0.48043 7.10536 0.734784 7 1 7H15C15.2652 7 15.5196 7.10536 15.7071 7.29289C15.8946 7.48043 16 7.73478 16 8C16 8.26522 15.8946 8.51957 15.7071 8.70711C15.5196 8.89464 15.2652 9 15 9H1C0.734784 9 0.48043 8.89464 0.292893 8.70711C0.105357 8.51957 0 8.26522 0 8ZM0 14C0 13.7348 0.105357 13.4804 0.292893 13.2929C0.48043 13.1054 0.734784 13 1 13H15C15.2652 13 15.5196 13.1054 15.7071 13.2929C15.8946 13.4804 16 13.7348 16 14C16 14.2652 15.8946 14.5196 15.7071 14.7071C15.5196 14.8946 15.2652 15 15 15H1C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14Z"
            fill="#111928"
          />
        </svg>
      </button>
      <button v-else @click="closeSidebar">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2121 0.797805C13.1208 0.706395 13.0125 0.633874 12.8932 0.584393C12.7739 0.534912 12.646 0.509443 12.5169 0.509443C12.3878 0.509443 12.2599 0.534912 12.1406 0.584393C12.0213 0.633874 11.913 0.706395 11.8217 0.797805L7 5.60968L2.17826 0.787944C2.08697 0.696654 1.9786 0.62424 1.85932 0.574834C1.74004 0.525428 1.61221 0.5 1.4831 0.5C1.354 0.5 1.22616 0.525428 1.10689 0.574834C0.98761 0.62424 0.879233 0.696654 0.787944 0.787944C0.696654 0.879233 0.62424 0.98761 0.574834 1.10689C0.525428 1.22616 0.5 1.354 0.5 1.4831C0.5 1.61221 0.525428 1.74004 0.574834 1.85932C0.62424 1.9786 0.696654 2.08697 0.787944 2.17826L5.60968 7L0.787944 11.8217C0.696654 11.913 0.62424 12.0214 0.574834 12.1407C0.525428 12.26 0.5 12.3878 0.5 12.5169C0.5 12.646 0.525428 12.7738 0.574834 12.8931C0.62424 13.0124 0.696654 13.1208 0.787944 13.2121C0.879233 13.3033 0.98761 13.3758 1.10689 13.4252C1.22616 13.4746 1.354 13.5 1.4831 13.5C1.61221 13.5 1.74004 13.4746 1.85932 13.4252C1.9786 13.3758 2.08697 13.3033 2.17826 13.2121L7 8.39032L11.8217 13.2121C11.913 13.3033 12.0214 13.3758 12.1407 13.4252C12.26 13.4746 12.3878 13.5 12.5169 13.5C12.646 13.5 12.7738 13.4746 12.8931 13.4252C13.0124 13.3758 13.1208 13.3033 13.2121 13.2121C13.3033 13.1208 13.3758 13.0124 13.4252 12.8931C13.4746 12.7738 13.5 12.646 13.5 12.5169C13.5 12.3878 13.4746 12.26 13.4252 12.1407C13.3758 12.0214 13.3033 11.913 13.2121 11.8217L8.39032 7L13.2121 2.17826C13.5868 1.80357 13.5868 1.1725 13.2121 0.797805Z"
            fill="black"
          />
        </svg>
      </button>
    </div>

<!--    <router-link :to="{ name: 'ShoppingCart' }" class="hidden md:block">-->
<!--      <div class="mr-6 cursor-pointer relative">-->
<!--        <div-->
<!--          class="absolute top-0 right-0 cart-number"-->
<!--          v-if="cartItems.length > 0"-->
<!--        >-->
<!--          {{ cartItems.length }}-->
<!--        </div>-->
<!--        <div class="icon-container" @mouseenter="openShoppingCartModal()">-->
<!--          <svg-->
<!--            v-if="!isHovering"-->
<!--            width="24"-->
<!--            height="24"-->
<!--            viewBox="0 0 24 24"-->
<!--            fill="none"-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--          >-->
<!--            <path-->
<!--              d="M3 3H5L5.4 5M7 13H17L21 5H5.4M7 13L5.4 5M7 13L4.70711 15.2929C4.07714 15.9229 4.52331 17 5.41421 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM9 19C9 20.1046 8.10457 21 7 21C5.89543 21 5 20.1046 5 19C5 17.8954 5.89543 17 7 17C8.10457 17 9 17.8954 9 19Z"-->
<!--              stroke="#111827"-->
<!--              stroke-width="1.7"-->
<!--              stroke-linecap="round"-->
<!--              stroke-linejoin="round"-->
<!--            />-->
<!--          </svg>-->
<!--          <svg-->
<!--            v-else-->
<!--            width="24"-->
<!--            height="24"-->
<!--            viewBox="0 0 24 24"-->
<!--            fill="none"-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--          >-->
<!--            <path-->
<!--              d="M3.60039 1.2002C2.93765 1.2002 2.40039 1.73745 2.40039 2.4002C2.40039 3.06294 2.93765 3.6002 3.60039 3.6002H5.06346L5.43013 5.06689C5.43398 5.08384 5.43819 5.10067 5.44275 5.11734L7.07142 11.632L6.00033 12.7031C4.48842 14.215 5.55921 16.8002 7.69739 16.8002H18.0003C18.6631 16.8002 19.2003 16.2629 19.2003 15.6002C19.2003 14.9375 18.6631 14.4002 18.0003 14.4002L7.69739 14.4002L8.89739 13.2002H16.8004C17.2549 13.2002 17.6704 12.9434 17.8737 12.5368L21.4737 5.33685C21.6597 4.96487 21.6398 4.5231 21.4212 4.16932C21.2025 3.81554 20.8163 3.6002 20.4004 3.6002H7.53732L7.16456 2.10915C7.03101 1.57495 6.55103 1.2002 6.00039 1.2002H3.60039Z"-->
<!--              fill="#786CE6"-->
<!--            />-->
<!--            <path-->
<!--              d="M19.2004 19.8002C19.2004 20.7943 18.3945 21.6002 17.4004 21.6002C16.4063 21.6002 15.6004 20.7943 15.6004 19.8002C15.6004 18.8061 16.4063 18.0002 17.4004 18.0002C18.3945 18.0002 19.2004 18.8061 19.2004 19.8002Z"-->
<!--              fill="#786CE6"-->
<!--            />-->
<!--            <path-->
<!--              d="M7.80039 21.6002C8.7945 21.6002 9.60039 20.7943 9.60039 19.8002C9.60039 18.8061 8.7945 18.0002 7.80039 18.0002C6.80628 18.0002 6.00039 18.8061 6.00039 19.8002C6.00039 20.7943 6.80628 21.6002 7.80039 21.6002Z"-->
<!--              fill="#786CE6"-->
<!--            />-->
<!--          </svg>-->
<!--        </div>-->
<!--      </div>-->
<!--    </router-link>-->

    <div
      class="flex place-items-center justify-start space-x-6 web-login"
      v-if="!isAuthenticated"
    >
      <TheLocaleSelector class="mr-6" />
      <router-link :to="{ name: 'Login' }">
        <TheButton
          bg="primary"
          style="
            border-radius: 19px;
            padding: 9px 17px;
            width: 104px;
            height: 38px;
            font-size: 14px;
          "
        >
          <div class="flex flex-row items-center min-w-28 justify-around">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              class="mr-2"
            >
              <path
                d="M7.58317 4.41667C7.50597 4.49294 7.44468 4.58377 7.40284 4.6839C7.36101 4.78404 7.33947 4.89148 7.33947 5C7.33947 5.10852 7.36101 5.21596 7.40284 5.3161C7.44468 5.41623 7.50597 5.50706 7.58317 5.58333L9.1665 7.16667H1.49984C1.0415 7.16667 0.666504 7.54167 0.666504 8C0.666504 8.45833 1.0415 8.83333 1.49984 8.83333H9.1665L7.58317 10.4167C7.50597 10.4929 7.44468 10.5838 7.40284 10.6839C7.36101 10.784 7.33947 10.8915 7.33947 11C7.33947 11.1085 7.36101 11.216 7.40284 11.3161C7.44468 11.4162 7.50597 11.5071 7.58317 11.5833C7.90817 11.9083 8.42484 11.9083 8.74984 11.5833L11.7415 8.59167C11.8188 8.51457 11.88 8.423 11.9219 8.32219C11.9637 8.22138 11.9852 8.11331 11.9852 8.00417C11.9852 7.89503 11.9637 7.78696 11.9219 7.68615C11.88 7.58534 11.8188 7.49376 11.7415 7.41667L8.74984 4.41667C8.67357 4.33947 8.58273 4.27817 8.4826 4.23634C8.38247 4.19451 8.27502 4.17297 8.1665 4.17297C8.05798 4.17297 7.95054 4.19451 7.85041 4.23634C7.75027 4.27817 7.65944 4.33947 7.58317 4.41667ZM15.6665 13.8333H9.83317C9.37484 13.8333 8.99984 14.2083 8.99984 14.6667C8.99984 15.125 9.37484 15.5 9.83317 15.5H15.6665C16.5832 15.5 17.3332 14.75 17.3332 13.8333V2.16667C17.3332 1.25 16.5832 0.5 15.6665 0.5H9.83317C9.37484 0.5 8.99984 0.875 8.99984 1.33333C8.99984 1.79167 9.37484 2.16667 9.83317 2.16667H15.6665V13.8333Z"
                fill="white"
              />
            </svg>
            {{ $t("enter") }}
          </div>
        </TheButton>
      </router-link>
      <!-- <router-link :to="{name:'Registration'}">
                    <TheButton bg="primary"> {{$t('to_register')}}</TheButton>
                  </router-link> -->
    </div>
    <Menu as="div" class="relative md:mr-0 web-login" v-else>
      <div>
        <MenuButton
          class="pr-3 max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <Avatar :url="active_user.avatar" />
        </MenuButton>
      </div>
      <TheTransition>
        <MenuItems
          class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <MenuItem>
            <TheButton
              class="block text-gray-700 w-full"
              bg="link"
              size="sm"
              @click="profile"
            >
              {{ $t("profile") }}
            </TheButton>
          </MenuItem>
          <MenuItem>
            <TheButton
              class="block text-gray-700 w-full"
              bg="link"
              size="sm"
              @click="logout"
            >
              {{ $t("logout") }}
            </TheButton>
          </MenuItem>
        </MenuItems>
      </TheTransition>
    </Menu>
  </TheCorePageHeader>
</template>
<script>
import TheButton from "@/components/Button/Button";
import ThePageHeaderMixin from "@/components/ThePageHeader/ThePageHeaderMixin";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import Avatar from "@/components/UserAvatar";
import TheTransition from "@/components/TheTransition";
import TheLocaleSelector from "@/components/TheLocaleSelector/TheLocaleSelector";
import TheCorePageHeader from "@/components/ThePageHeader/TheCorePageHeader";
import TheLogo from "@/assets/images/Logo";
import TheLandingSidebar from "@/components/TheSidebar/TheLandingSidebar.vue";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import ShoppingCartModal from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartModal.vue";

export default {
  components: {
    ShoppingCartModal,
    TheLandingSidebar,
    TheLogo,
    TheCorePageHeader,
    TheLocaleSelector,
    TheTransition,
    Avatar,
    TheButton,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  data() {
    return {
      landingSidebar: false,
      modalOpen: false,
      isHovering: false,
    };
  },
  methods: {
    scrollToFooter() {
      this.$emit("scrollToFooter");
    },
    toggleHoverState() {
      this.isHovering = !this.isHovering;
    },
    openShoppingCartModal() {
      this.modalOpen = true;
      console.log("open", this.modalOpen);
    },
    closeShoppingCartModal() {
      this.modalOpen = false;
      console.log("close", this.modalOpen);
    },
    openSidebar() {
      this.landingSidebar = true;
    },
    closeSidebar() {
      this.landingSidebar = false;
    },
  },

  mixins: [ThePageHeaderMixin, ShoppingCartMixin],

  computed: {
    currentRoute() {
      return this.$route.name;
    },
    isMyCoursesRoute() {
      const routes = [
        "WaitingForStart",
        "MyCourses",
        "CurrentCourses",
        "CourseOverview",
        "CourseContent",
      ];
      return routes.indexOf(this.currentRoute) > -1;
    },
  },
};
</script>

<style scoped>
.hover-icon {
  display: none;
}

.icon-container:hover .hover-icon {
  display: block;
}

.icon-container:hover .normal-icon {
  display: none;
}
.cart-number {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px; /* Set the width and height to create a perfect circle */
  height: 20px;
  border-radius: 50%; /* Use border-radius: 50% to create a circle */
  background: #374151;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  transform: translateX(50%) translateY(-50%);
}
.current-route {
  color: rgb(79 70 229);
}
.not-current-route {
  color: rgb(31 41 55);
}
@media (max-width: 650px) {
  .sidebar {
    display: block;
  }
  .web-login {
    display: none;
  }
  .mob-header {
    display: flex;
    padding: 16px 0;
  }
}
</style>
