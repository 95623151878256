<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import { Chart, BarElement, CategoryScale, LinearScale } from "chart.js";
import { ref, onMounted } from "vue";

Chart.register(BarElement, CategoryScale, LinearScale);

export default {
  setup() {
    const chartCanvas = ref(null);

    onMounted(() => {
      const ctx = chartCanvas.value.getContext("2d");

      const dataValues = [80, 85, 60, 22, 90, 84];

      const backgroundColors = dataValues.map(value => {
        if (value > 70) return "#ECFDF5";
        if (value >= 40) return "#FFEADC";
        return "#FEF2F2";
      });

      const borderColors = dataValues.map(value => {
        if (value > 70) return "#059669";
        if (value >= 40) return "#F97316";
        return "#DC2626";
      })
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Домашние задания", "Тесты", "Индивидуальные работы", "Групповые работы", "Midterms", "Final"],
          datasets: [
            {
              // ❌ Убираем label, чтобы не было кнопки
              data: dataValues,
              backgroundColor: backgroundColors,
              borderColor: borderColors,
              borderWidth: 2,
              borderRadius: 20,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              ticks: { font: { size: 16 } },
            },
            y: {
              beginAtZero: true,
              max: 100,
              ticks: { font: { size: 16 } },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
        },
      });

    });

    return { chartCanvas };
  },
};
</script>
